import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Box, Grid, Divider } from '@mui/material';
import { CognitoIdentityProviderClient, ListUsersCommand } from "@aws-sdk/client-cognito-identity-provider";
import awsconfig from '../aws-exports';

const client = new CognitoIdentityProviderClient({ 
  region: awsconfig.Auth.region,
  credentials: {
    accessKeyId: awsconfig.Auth.accessKey,
    secretAccessKey: awsconfig.Auth.secretAccessKey
  }
});

function TruckerRegistration() {
  const [dotNumber, setDotNumber] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [dotVerified, setDotVerified] = useState(false);
  const [truckerDetails, setTruckerDetails] = useState({});
  const [truckPhotos, setTruckPhotos] = useState([]);
  const [thumbnailUrls, setThumbnailUrls] = useState([]);
  const [registration, setRegistration] = useState({});
  const [carrierDetails, setCarrierDetail] = useState([]);
  
  const WEB_KEY = '?webKey=d7a0a995e5d1add2ff042d46fd6e8bdfb42d8edf';

  const handleFileChange = (e) => {
    const files = e.target.files;
    setTruckPhotos(files);
    const urls = Array.from(files).map(file => URL.createObjectURL(file));
    setThumbnailUrls(urls);
  };

  const verifyDotNumber = async () => {
    if (!dotNumber) {
      alert("Please enter a DOT number to verify.");
      return;
    }
    const verifyDotUrl = `https://mobile.fmcsa.dot.gov/qc/services/carriers/${dotNumber}${WEB_KEY}`;
    try {
      const response = await axios.get(verifyDotUrl);
      if (response.data.content.carrier.allowedToOperate === "Y") {
        const details = {
          legalName: response.data.content.carrier.legalName,
          dbaName: response.data.content.carrier.dbaName,
          einNumber: response.data.content.carrier.ein,
          insuranceCoverage: response.data.content.carrier.bipdInsuranceOnFile
        };
        setCarrierDetail(response.data.content.carrier);
        setTruckerDetails(details);
        setRegistration(prev => ({ ...prev, carrierDetails: details}));
        setDotVerified(true);
        alert('DOT Number is valid!');
      } else {
        setDotVerified(false);
        alert('DOT Number is not valid. Please check and try again.');
      }
    } catch (error) {
      console.error('There was an error verifying the DOT number:', error);
      alert('Failed to verify DOT Number.');
      setDotVerified(false);
    }
  };

  const checkEmailExists = async (email) => {
    const command = new ListUsersCommand({
      UserPoolId: awsconfig.Auth.userPoolId,
      Filter: `email = "${email}"`,
    });

    try {
      const response = await client.send(command);
      return response.Users && response.Users.length > 0;
    } catch (error) {
      console.error("Error checking email:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!dotVerified) {
      alert("Please verify the DOT number before submitting.");
      return;
    }

    if (!email || emailError) {
      alert("Please enter a valid email address.");
      return;
    }

    try {
      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        alert("This email address is already in use.");
        return;
      }

      const registrationData = {
        name,
        phone,
        email,
        carrierDetails
      };

      const response = await axios.post('https://fm7s2rm8sb.execute-api.us-east-1.amazonaws.com/dev/Trucker', registrationData);
      if (response.data.statusCode === 200) {
        const { insertedId } = JSON.parse(response.data.body);
        console.log("Registration successful with ID:", insertedId);
        alert('Registration successful!');

        // Now proceed with file uploads using the insertedId as prefix
        for (const file of truckPhotos) {
          const fileName = `${insertedId}/${file.name}`;
          try {
            // Get the presigned URL for each file
            const presignedResponse = await axios.post(`https://fm7s2rm8sb.execute-api.us-east-1.amazonaws.com/dev/Trucker/presigner`, {
              filename: fileName,
              fileType: file.type
            });
            const body = JSON.parse(presignedResponse.data.body);
            const { uploadUrl } = body;

            if (!uploadUrl) {
              throw new Error("Presigned URL is missing");
            }

            // Use the presigned URL to upload the file
            await axios.put(uploadUrl, file, {
              headers: {
                'Content-Type': 'image/jpeg'
              }
            });
            console.log("File uploaded successfully:", fileName);
          } catch (uploadError) {
            console.error("Error uploading file:", fileName, uploadError);
          }
        }
      }
    } catch (error) {
      console.error('There was an error submitting the registration:', error);
      alert('Failed to submit registration.');
    }
  };

  const validateAndFormatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return value;
  };

  const handlePhoneChange = (e) => {
    const formattedPhone = validateAndFormatPhoneNumber(e.target.value);
    setPhone(formattedPhone);
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        padding: { xs: 1, md: 2 },
        borderRadius: 2,
        '& .MuiTextField-root': {
          '& label.Mui-focused': {
            color: 'white',
          },
          '& label': {
            color: 'white',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
            '& input': {
              color: 'white',
            },
          },
        },
      }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Register Trucker
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="DOT#"
                variant="outlined"
                value={dotNumber}
                onChange={(e) => setDotNumber(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" color="inherit" onClick={verifyDotNumber}>
                Verify
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Legal Name as registered with DOT"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                value={phone}
                onChange={handlePhoneChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                error={!!emailError}
                helperText={emailError}
                required
              />
            </Grid>
          </Grid>
          {dotVerified && (
            <Box sx={{ marginTop: 2 }}>
              <Divider sx={{ marginBottom: 2 }} />
              <Typography variant="h6" gutterBottom>Trucker Details:</Typography>
              <Typography>Legal Name: {truckerDetails.legalName || 'N/A'}</Typography>
              <Typography>DBA Name: {truckerDetails.dbaName || 'N/A'}</Typography>
              <Typography>IEN Number: {truckerDetails.einNumber || 'N/A'}</Typography>
              <Typography>Insurance Coverage: {truckerDetails.insuranceCoverage || 'N/A'}</Typography>
            </Box>
          )}
          <Typography variant="body1" sx={{ marginTop: 2 }}>Upload a picture of your Truck and Trailer(optional), Also the front of a valid Drivers License</Typography>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ marginTop: 8 }}
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: 2 }}>
            {thumbnailUrls.map((url, index) => (
              <Box key={index} sx={{ marginRight: 2, marginBottom: 2 }}>
                <img src={url} alt={`Truck Thumbnail ${index + 1}`} style={{ width: 100, height: 100, objectFit: 'cover' }} />
              </Box>
            ))}
          </Box>
          <Typography variant="body2" sx={{ marginTop: 2 }}>
            <strong>Legal Notice:</strong> You are registering with CrossBown LLC as {truckerDetails.legalName}, DBA {truckerDetails.dbaName || 'N/A'}
          </Typography>
          <Button variant="outlined" color="inherit" type="submit" sx={{ marginTop: 2 }} disabled={!dotVerified}>
            Register
          </Button>
        </form>
      </Box>
    </Container>
  );
}

export default TruckerRegistration;
import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CognitoIdentityProviderClient, ListUsersCommand } from "@aws-sdk/client-cognito-identity-provider";
import awsconfig from '../aws-exports';

const client = new CognitoIdentityProviderClient({ 
  region: awsconfig.Auth.region,
  credentials: {
    accessKeyId: awsconfig.Auth.accessKey,
    secretAccessKey: awsconfig.Auth.secretAccessKey
  }
});

function ShipperRegistration() {
  const [shipperName, setShipperName] = useState('');
  const [dbaName, setDbaName] = useState('');
  const [address, setAddress] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const navigate = useNavigate();

  const isValidEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidPhone = phone => /^\(\d{3}\) \d{3}-\d{4}$/.test(phone);
  const isValidZipcode = zip => /^\d{5}$/.test(zip);

  const formatPhoneNumber = (value) => {
    return value
      .replace(/\D/g, '')
      .substring(0, 10)
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };

  const handleZipcodeChange = async (e) => {
    const zip = e.target.value;
    setZipcode(zip);
    if (isValidZipcode(zip)) {
      try {
        const data = { "zipCode": zip }
        const response = await axios.post('https://fm7s2rm8sb.execute-api.us-east-1.amazonaws.com/dev/shipper/reversezip', data);
        
        if (response.data && response.data.body) {
          setCity(response.data.body.city);
          setState(response.data.body.state);
        } else {
          setCity('');
          setState('');
        }
      } catch (error) {
        console.error('Failed to fetch city and state:', error);
        setCity('');
        setState('');
      }
    } else {
      setCity('');
      setState('');
    }
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setContactPhone(formattedPhoneNumber);
  };

  const checkEmailExists = async (email) => {
    const command = new ListUsersCommand({
      UserPoolId: awsconfig.Auth.userPoolId,
      Filter: `email = "${email}"`,
    });

    try {
      const response = await client.send(command);
      return response.Users && response.Users.length > 0;
    } catch (error) {
      console.error("Error checking email:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidEmail(contactEmail)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (!isValidPhone(contactPhone)) {
      alert("Please enter a valid phone number.");
      return;
    }
    if (!isValidZipcode(zipcode)) {
      alert("Please enter a valid 5-digit zipcode.");
      return;
    }

    try {
      const emailExists = await checkEmailExists(contactEmail);
      if (emailExists) {
        alert("This email address is already in use.");
        return;
      }

      const shipperData = {
        shipperName,
        dbaName,
        address,
        zipcode,
        city,
        state,
        contactName,
        contactEmail,
        contactPhone
      };

      const response = await axios.post('https://fm7s2rm8sb.execute-api.us-east-1.amazonaws.com/dev/shipper', shipperData);
      console.log(response.data);
      alert('Registration successful! Please check your email for your username and password.');
      
      // Clear the form
      setShipperName('');
      setDbaName('');
      setAddress('');
      setZipcode('');
      setCity('');
      setState('');
      setContactName('');
      setContactEmail('');
      setContactPhone('');
      
      // Redirect to home page
      navigate('/');
    } catch (error) {
      console.error('There was an error registering the shipper:', error);
      alert('Failed to register shipper. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: 2,
        borderRadius: 2,
        '& .MuiTextField-root': {
          marginBottom: 2,
          '& label': {
            color: 'white'
          },
          '& input': {
            color: 'white',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
        },
        '@media (max-width: 600px)': {
          padding: 1,
        }
      }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Register Shipper
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Shipper Name"
            variant="outlined"
            value={shipperName}
            onChange={(e) => setShipperName(e.target.value)}
            required
          />
          <TextField
            fullWidth
            label="DBA Name"
            variant="outlined"
            value={dbaName}
            onChange={(e) => setDbaName(e.target.value)}
          />
          <TextField
            fullWidth
            label="Street Address"
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
          <TextField
            fullWidth
            label="Zipcode"
            variant="outlined"
            value={zipcode}
            onChange={handleZipcodeChange}
            required
          />
          <TextField
            fullWidth
            label="City"
            variant="outlined"
            value={city}
            InputProps={{
              readOnly: true,
            }}
            required
          />
          <TextField
            fullWidth
            label="State"
            variant="outlined"
            value={state}
            InputProps={{
              readOnly: true,
            }}
            required
          />
          <TextField
            fullWidth
            label="Contact Name"
            variant="outlined"
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
            required
          />
          <TextField
            fullWidth
            label="Contact Email"
            variant="outlined"
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
            required
            error={contactEmail && !isValidEmail(contactEmail)}
            helperText={contactEmail && !isValidEmail(contactEmail) ? 'Enter a valid email' : ''}
          />
          <TextField
            fullWidth
            label="Contact Phone"
            variant="outlined"
            value={contactPhone}
            onChange={handlePhoneChange}
            required
            error={contactPhone && !isValidPhone(contactPhone)}
            helperText={contactPhone && !isValidPhone(contactPhone) ? 'Enter a valid phone number' : ''}
          />
          <Button variant="outlined" color="inherit" type="submit">Register</Button>
        </form>
      </Box>
    </Container>
  );
}

export default ShipperRegistration;
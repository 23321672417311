import React from 'react';
import { Box, Typography, Button, Card, CardContent, CardActions } from '@mui/material';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      gap: 2, 
      marginTop: 8, 
      backgroundColor: 'rgba(0, 0, 0, 0.7)', 
      padding: 4, 
      borderRadius: 2, 
      color: 'white'
    }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'white', textAlign: 'center' }}>
        Welcome to Freight Finder USA
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ color: 'white', textAlign: 'center' }}>
        Connecting truckers and shippers seamlessly. Whether you're looking to fill up your truck's unused space or need a reliable shipper for your partial load, we've got you covered. Explore opportunities or register to start today.
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', md: 'row' }, 
        gap: 2, 
        width: '100%',
        justifyContent: 'center'
      }}>
        <Card variant="outlined" sx={{ 
          minWidth: 275, 
          backgroundColor: 'rgba(255, 255, 255, 0.1)', 
          color: 'white', 
          flex: 1,
          maxWidth: { xs: '100%', md: '45%' } 
        }}>
          <CardContent>
            <Typography variant="h5" component="div" sx={{ color: 'white' }}>
              For Truckers
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              Already have a load but want to maximize your truck's efficiency and profit? Freight Finder USA helps you find partial loads to fill up that extra space, reducing empty miles and boosting your earnings. Join our network to discover new opportunities and make the most out of every trip.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" component={Link} to="/register-trucker" sx={{ color: 'white' }}>
              Register for Free
            </Button>
          </CardActions>
        </Card>
        <Card variant="outlined" sx={{ 
          minWidth: 275, 
          backgroundColor: 'rgba(255, 255, 255, 0.1)', 
          color: 'white', 
          flex: 1,
          maxWidth: { xs: '100%', md: '45%' } 
        }}>
          <CardContent>
            <Typography variant="h5" component="div" sx={{ color: 'white' }}>
              For Shippers
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              Need to move a partial load but can't find the right fit? Freight Finder USA connects you with truckers who have available space and are ready to help. Get your goods moving efficiently and affordably by registering today. Enjoy the benefits of optimized shipping solutions tailored to your needs.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" component={Link} to="/register-shipper" sx={{ color: 'white' }}>
              Register for Free
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
}

export default Home;

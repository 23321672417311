import React, { useEffect, useRef } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

const MapComponent = () => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const apiKey = "v1.public.eyJqdGkiOiI2NWVlMTUxYy1lZDc5LTQzMzAtYmM0NS03Y2RiZDkyNDY0OTcifUcdxBBp2IsZ1hRWLkWve2sgHXkz2EEm-6Zdw8Svr7y67_tfHlXmNeFr9921EXSALC475c428wQn6Qct8yUPz_eCKUhPlP5FUhuut0rT8iYzU4QZ95vhj5nPGfFdQFSDEVjlZXS0qGEr3dsjEdF-e8AvuQVyZ8MYbtZ5NaBvB8E-NZK4iGdf4wP_SgIEyEQZeyOHQPOX4GvDV8gJ8v9O_lHQiYUc_--1NgxtpHolyAtFgguOg8KmFi0rDS8Q0k_e1PYE4HXW_Apu5XQGL18IeS6FO_S1e34PY3Fh6srcB9d-9Dcjo5s2ykR1LPSEt5PkHXKc1158BAirPXOmYaCuUvo.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx";
    const mapName = "freightfinderusa";
    const region = "us-east-1";

    const map = new maplibregl.Map({
      container: mapContainerRef.current,
      style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
      center: [-123.115898, 49.295868],
      zoom: 11,
    });

    map.addControl(new maplibregl.NavigationControl(), "top-left");

    return () => {
      map.remove();
    };
  }, []);

  return <div id="map" ref={mapContainerRef} style={{ height: '100vh' }} />;
};

export default MapComponent;

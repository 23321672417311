import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
    UserPoolId: 'us-east-1_Cj53oPfbb', // Your user pool id here
    ClientId: '4vdf05f9mi43pm4qegj7nbah2k' // Your client id here
};

const userPool = new CognitoUserPool(poolData);

export default userPool;

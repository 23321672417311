// src/aws-exports.js
const awsconfig = {
    Auth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_Cj53oPfbb',
        userPoolWebClientId: '4vdf05f9mi43pm4qegj7nbah2k',
        secretAccessKey:'UaFbvyJWyqVtgh+MNl2F+/f1++SIjPvuy0ygU6py'  ,
        accessKey:'AKIAYS2NRUXPB3ONLUNX'
    }
};

export default awsconfig;

import React, { useState } from 'react';
import {
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TextField, Button, Box, IconButton, Card, CardContent
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import MapComponent from './MapComponent'; // Import the new MapComponent

function LoadList() {
  const [dummyLoads, setDummyLoads] = useState([
    {
      id: 1,
      date: '2024-06-30',
      shipper: 'Acme Corp',
      pickupLocation: '1234 West St, New York, NY',
      destination: '4321 East St, Los Angeles, CA',
      width: '8.2 ft',
      height: '6.6 ft',
      weight: '1102 lbs',
      length: '10 ft',
      costToShipper: '1500',
      booked: true,
      driver: 'John Doe'
    },
    {
      id: 2,
      date: '2024-07-05',
      shipper: 'Beta Ltd',
      pickupLocation: '9876 South Ave, Chicago, IL',
      destination: '6789 North Rd, Houston, TX',
      width: '9.8 ft',
      height: '8.2 ft',
      weight: '1653 lbs',
      length: '15 ft',
      costToShipper: '2000',
      booked: false,
      driver: null
    }
  ]);

  const [newLoad, setNewLoad] = useState({
    shipper: '',
    pickupLocation: '',
    destination: '',
    width: '',
    height: '',
    weight: '',
    length: '',
    costToShipper: '',
  });

  const [showAddLoadForm, setShowAddLoadForm] = useState(false);
  const [calculatedCost, setCalculatedCost] = useState(null);
  const [routeDetails, setRouteDetails] = useState(null);
  const [widthError, setWidthError] = useState('');
  const [heightError, setHeightError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'width') {
      if (parseFloat(value) > 8) {
        setWidthError('Width cannot exceed 8 feet.');
      } else {
        setWidthError('');
      }
    }

    if (name === 'height') {
      if (parseFloat(value) > 8) {
        setHeightError('Height cannot exceed 8 feet.');
      } else {
        setHeightError('');
      }
    }

    setNewLoad({
      ...newLoad,
      [name]: value,
    });
  };

  const calculateCostAndRoute = async () => {
    const { pickupLocation, destination, width, height, weight, length } = newLoad;

    const response = await axios.post('https://fm7s2rm8sb.execute-api.us-east-1.amazonaws.com/dev/shipper/ratecalculator', {
      fromAddress: pickupLocation,
      toAddress: destination,
      width,
      height,
      weight,
      length,
    });

    if (response.data && response.data.body && response.data.body.route && response.data.body.cost) {
      setCalculatedCost(response.data.body.cost);
      setRouteDetails(response.data.body.route);
      return response.data.body.route;
    } else {
      throw new Error('Failed to get cost and route');
    }
  };

  const handleAddLoad = async () => {
    await calculateCostAndRoute();
    const newLoadEntry = {
      ...newLoad,
      costToShipper: calculatedCost,
      id: dummyLoads.length + 1,
      date: new Date().toISOString().split('T')[0],
      booked: false,
      driver: '',
    };

    setDummyLoads([...dummyLoads, newLoadEntry]);
    setNewLoad({
      shipper: '',
      pickupLocation: '',
      destination: '',
      width: '',
      height: '',
      weight: '',
      length: '',
      costToShipper: '',
    });
    setCalculatedCost(null);
    setRouteDetails(null);
    setShowAddLoadForm(false);
  };

  const RouteCard = ({ route }) => (
    <Card sx={{ margin: '20px', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white' }}>
      <CardContent>
        <Typography variant="h5" component="div">Route Details</Typography>
        <Typography variant="body1">Total Distance: {route.Legs[0].Distance} miles</Typography>
        <Typography variant="h6" component="div" sx={{ marginTop: '10px' }}>Legs:</Typography>
        {route.Legs.map((leg, index) => (
          <Typography key={index} variant="body2">Leg {index + 1}: {leg.Distance} miles, Duration: {leg.DurationSeconds} seconds</Typography>
        ))}
      </CardContent>
    </Card>
  );

  return (
    <div>
      <IconButton onClick={() => setShowAddLoadForm(!showAddLoadForm)} sx={{ margin: '20px', color: 'white' }}>
        <AddIcon /> New Load
      </IconButton>
      {showAddLoadForm && (
        <Box sx={{ margin: '20px', padding: '20px', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: 2 }}>
          <Typography variant="h4" style={{ color: 'white' }}>Add New Load</Typography>
          <form>
            <TextField
              label="Shipper"
              name="shipper"
              value={newLoad.shipper}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                classes: {
                  notchedOutline: 'MuiOutlinedInput-notchedOutline',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label="Pickup Location"
              name="pickupLocation"
              value={newLoad.pickupLocation}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                classes: {
                  notchedOutline: 'MuiOutlinedInput-notchedOutline',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label="Destination"
              name="destination"
              value={newLoad.destination}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                classes: {
                  notchedOutline: 'MuiOutlinedInput-notchedOutline',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label="Width (ft)"
              name="width"
              value={newLoad.width}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              error={!!widthError}
              helperText={widthError}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                classes: {
                  notchedOutline: 'MuiOutlinedInput-notchedOutline',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label="Height (ft)"
              name="height"
              value={newLoad.height}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              error={!!heightError}
              helperText={heightError}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                classes: {
                  notchedOutline: 'MuiOutlinedInput-notchedOutline',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label="Weight (lbs)"
              name="weight"
              value={newLoad.weight}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                classes: {
                  notchedOutline: 'MuiOutlinedInput-notchedOutline',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label="Length (ft)"
              name="length"
              value={newLoad.length}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{
                style: { color: 'white' },
                classes: {
                  notchedOutline: 'MuiOutlinedInput-notchedOutline',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
            />
            <Button onClick={calculateCostAndRoute} variant="contained" color="primary" sx={{ marginTop: 2 }}>
              Calculate Load
            </Button>
            {calculatedCost && (
              <>
                <Typography variant="h6" style={{ color: 'white', marginTop: 2 }}>
                  Calculated Cost: ${calculatedCost}
                </Typography>
                {routeDetails && <RouteCard route={routeDetails} />}
                <Button onClick={handleAddLoad} variant="contained" color="secondary" sx={{ marginTop: 2 }}>
                  Accept and Post Load
                </Button>
              </>
            )}
          </form>
        </Box>
      )}

      <TableContainer component={Paper} sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', borderRadius: 2
      }}>
        <Typography variant="h4" style={{ margin: '20px', color: 'white' }}>Load Board</Typography>
        <Table sx={{ minWidth: 850 }} aria-label="loads table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>Date</TableCell>
              <TableCell sx={{ color: 'white' }}>Shipper</TableCell>
              <TableCell sx={{ color: 'white' }}>Pickup Location</TableCell>
              <TableCell sx={{ color: 'white' }}>Destination</TableCell>
              <TableCell sx={{ color: 'white' }}>Width (ft)</TableCell>
              <TableCell sx={{ color: 'white' }}>Height (ft)</TableCell>
              <TableCell sx={{ color: 'white' }}>Weight (lbs)</TableCell>
              <TableCell sx={{ color: 'white' }}>Length (ft)</TableCell>
              <TableCell sx={{ color: 'white' }}>Cost to Shipper</TableCell>
              <TableCell sx={{ color: 'white' }}>Booked</TableCell>
              <TableCell sx={{ color: 'white' }}>Driver</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyLoads.map((load) => (
              <TableRow key={load.id}>
                <TableCell sx={{ color: 'white' }}>{load.date}</TableCell>
                <TableCell sx={{ color: 'white' }}>{load.shipper}</TableCell>
                <TableCell sx={{ color: 'white' }}>{load.pickupLocation}</TableCell>
                <TableCell sx={{ color: 'white' }}>{load.destination}</TableCell>
                <TableCell sx={{ color: 'white' }}>{load.width}</TableCell>
                <TableCell sx={{ color: 'white' }}>{load.height}</TableCell>
                <TableCell sx={{ color: 'white' }}>{load.weight}</TableCell>
                <TableCell sx={{ color: 'white' }}>{load.length}</TableCell>
                <TableCell sx={{ color: 'white' }}>${load.costToShipper}</TableCell>
                <TableCell sx={{ color: 'white' }} padding="checkbox">
                  <Checkbox checked={load.booked} sx={{ color: 'white' }} />
                </TableCell>
                <TableCell sx={{ color: 'white' }}>{load.driver}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <MapComponent />
    </div>
  );
}

export default LoadList;

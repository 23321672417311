import React, { useEffect } from 'react';

const Login = () => {
  useEffect(() => {
    window.location.href = "https://freightfinder.auth.us-east-1.amazoncognito.com/login?client_id=4vdf05f9mi43pm4qegj7nbah2k&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Ffreightfinderusa.com%2Floads";
  }, []);

  return <div>Redirecting to login...</div>;
};

export default Login;

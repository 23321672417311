// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container, Menu, MenuItem, useMediaQuery, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Parallax } from 'react-parallax';
import TruckerRegistration from './components/TruckerRegistration';
import ShipperRegistration from './components/ShipperRegistration';
import Home from './components/Home';
import LoadList from './components/LoadList';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import Login from './components/Login';
import Register from './components/Register';
import LogoutButton from './components/LogoutButton'; // Import the LogoutButton component
import backgroundImage from './flatbedwithmustang.png';
import './App.css';

function App() {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [registrationMenuAnchorEl, setRegistrationMenuAnchorEl] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleRegistrationMenuOpen = (event) => {
    setRegistrationMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMobileMoreAnchorEl(null);
    setRegistrationMenuAnchorEl(null);
  };

  return (
    <Router>
      <AppBar position="fixed" style={{ zIndex: 1400, backgroundColor: 'black', height: '50px' }}>
        <Toolbar style={{ minHeight: '50px', padding: '0 10px' }}>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Freight Finder USA
          </Typography>
          {isMobile ? (
            <>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMobileMenuOpen}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={mobileMoreAnchorEl}
                keepMounted
                open={Boolean(mobileMoreAnchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem component={Link} to="/" onClick={handleMenuClose}>Home</MenuItem>
                <MenuItem component={Link} to="/loads" onClick={handleMenuClose}>View Loads</MenuItem>
                <MenuItem
                  aria-controls="registration-menu"
                  aria-haspopup="true"
                  onClick={handleRegistrationMenuOpen}
                >
                  Registration
                </MenuItem>
                <Menu
                  id="registration-menu"
                  anchorEl={registrationMenuAnchorEl}
                  keepMounted
                  open={Boolean(registrationMenuAnchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem component={Link} to="/register-trucker" onClick={handleMenuClose}>Register Trucker</MenuItem>
                  <MenuItem component={Link} to="/register-shipper" onClick={handleMenuClose}>Register Shipper</MenuItem>
                </Menu>
                <MenuItem onClick={handleMenuClose}><LogoutButton /></MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Button color="inherit" component={Link} to="/">Home</Button>
              <Button color="inherit" component={Link} to="/loads">View Loads</Button>
              <Button
                color="inherit"
                aria-controls="registration-menu"
                aria-haspopup="true"
                onClick={handleRegistrationMenuOpen}
              >
                Registration
              </Button>
              <Menu
                id="registration-menu"
                anchorEl={registrationMenuAnchorEl}
                keepMounted
                open={Boolean(registrationMenuAnchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem component={Link} to="/register-trucker" onClick={handleMenuClose}>Register Trucker</MenuItem>
                <MenuItem component={Link} to="/register-shipper" onClick={handleMenuClose}>Register Shipper</MenuItem>
              </Menu>
              <LogoutButton />
            </>
          )}
        </Toolbar>
      </AppBar>
      <Parallax className="parallax-bg" bgImage={backgroundImage} strength={500}>
        <Container className="content-container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/loads" element={<AuthenticatedRoute component={LoadList} />} />
            <Route path="/register-trucker" element={<TruckerRegistration />} />
            <Route path="/register-shipper" element={<ShipperRegistration />} />
          </Routes>
        </Container>
      </Parallax>
    </Router>
  );
}

export default App;

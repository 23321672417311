import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import userPool from '../cognitoConfig';

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Sign out from Cognito User Pool
    const user = userPool.getCurrentUser();
    if (user) {
      user.signOut();
    }

    // Clear access token and other relevant data from local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('refreshToken');

    // Redirect to the login page
    navigate('/login');
  };

  return (
    <Button color="inherit" onClick={handleLogout}>
      Logout
    </Button>
  );
};

export default LogoutButton;

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import queryString from 'query-string';

function AuthenticatedRoute({ component: Component, ...rest }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    const code = query.code;

    // Check if there's already an access token in localStorage
    const storedToken = localStorage.getItem("accessToken");
    if (storedToken) {
      setIsAuthenticated(true);
      setLoading(false);
      return;
    }

    if (code) {
      const clientId = "4vdf05f9mi43pm4qegj7nbah2k";
      const redirectUri = "https://freightfinderusa.com/loads";
      const tokenUrl = `https://freightfinder.auth.us-east-1.amazoncognito.com/oauth2/token`;

      const body = new URLSearchParams({
        grant_type: "authorization_code",
        client_id: clientId,
        redirect_uri: redirectUri,
        code: code
      });

      fetch(tokenUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: body.toString()
      })
        .then(response => response.json())
        .then(data => {
          if (data.access_token) {
            localStorage.setItem("accessToken", data.access_token);
            setIsAuthenticated(true);
          } else {
            console.error("Token exchange failed", data);
            setIsAuthenticated(false);
          }
          setLoading(false);
        })
        .catch(error => {
          console.error("Token exchange error", error);
          setIsAuthenticated(false);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
}

export default AuthenticatedRoute;
